import type { NormalizedCacheObject } from '@apollo/client/cache';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  RequestHandler,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { ApolloClients, provideApolloClients } from '@vue/apollo-composable';
import { sha256 } from 'crypto-hash';

import { DEFAULT_LOCALE, Environment } from '~/core';
import { inMemoryCacheConfig } from '~/domains/graphql';
import { getRawCookie } from '~/helpers/cookie.helper';

import { headerLink } from './apolloLinks/headerLink';
import { errorLink as maintenanceErrorLink } from './apolloLinks/maintenanceLink';
import { refreshTokenLink } from './apolloLinks/refreshTokenLink';

type ApolloPluginClients = Record<string, ApolloClient<unknown>>;

const apolloPersistedLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

function useApolloHeaders(): Readonly<Record<string, string | undefined>> {
  const nuxt = useNuxtApp();
  const requestHeaders = useRequestHeaders();
  const requestEvent = useRequestEvent();
  const config = useRuntimeConfig();
  const route = useRoute();

  const language = nuxt.vueApp._context.config.globalProperties.$i18n.locale;
  const languages = nuxt.vueApp._context.config.globalProperties.$i18n.locales;
  const rawLanguages = (isProxy(languages) ? toRaw(languages) : languages) as {
    code: string;
    iso: string;
  }[];
  const locale =
    rawLanguages.find(({ code }) => code === language)?.iso ?? DEFAULT_LOCALE;
  const isDraft = useContentPreview();

  const headers: Record<string, string> = {
    'accept-language': locale ?? requestHeaders['accept-language'],
    'x-language': language,
    'x-locale': locale,
    'cloudfront-viewer-country': requestHeaders['cloudfront-viewer-country'],
    'x-preview': String(isDraft.value),
  };

  if (config.public.appEnv !== Environment.Pro && route.query.cache === '0') {
    headers['x-cache'] = '0';
  }

  const rawCookie = getRawCookie(requestEvent);

  if (rawCookie) {
    headers.cookie = rawCookie;
  }

  return headers;
}

export function getDefaultApolloClient(): ApolloClient<NormalizedCacheObject> {
  const config = useRuntimeConfig();
  const route = useRoute();
  const router = useRouter();
  const localePath = useLocalePath();

  const apolloHeaders = useApolloHeaders();

  const refreshHeadersLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => {
      return {
        headers: apolloHeaders,
      };
    });

    return forward(operation);
  });

  const errorLink = onError(({ forward, operation }) => {
    forward(operation);
  });

  const links: Array<ApolloLink | RequestHandler> =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? []
      : [apolloPersistedLink];

  links.push(
    refreshHeadersLink,
    refreshTokenLink,
    maintenanceErrorLink(router, localePath),
    errorLink,
    headerLink,
    new HttpLink({
      credentials: 'same-origin',
      uri: config.serverApolloUri ?? config.public.browserApolloUri,
    }),
  );

  const link = ApolloLink.from(links);

  const cache = new InMemoryCache(inMemoryCacheConfig);

  return new ApolloClient<NormalizedCacheObject>({
    cache,
    connectToDevTools: !(import.meta.env.SSR && import.meta.env.DEV),
    link,
    ssrForceFetchDelay: import.meta.env.SSR ? undefined : 100,
    ssrMode: Boolean(
      import.meta.env.SSR && config.public.appEnv !== Environment.Pro
        ? route.query.ssr !== '0'
        : import.meta.env.SSR,
    ),
  });
}

export default defineNuxtPlugin(() => {
  const nuxt = useNuxtApp();

  const defaultApolloClient = getDefaultApolloClient();

  nuxt.hook('app:rendered', () => {
    if (!nuxt.payload.data) {
      nuxt.payload.data = {};
    }

    nuxt.payload.data['apollo-cache'] = defaultApolloClient.extract();
  });

  defaultApolloClient.restore(
    JSON.parse(JSON.stringify(nuxt.payload.data?.['apollo-cache'] ?? '{}')),
  );

  const clients: ApolloPluginClients = { default: defaultApolloClient };

  const defaultClient = clients?.default || clients[Object.keys(clients)[0]];

  provideApolloClients(clients);
  nuxt.vueApp.provide(ApolloClients, clients);
  nuxt._apolloClients = clients;

  return {
    provide: {
      apollo: { clients, defaultClient },
    },
  };
});
