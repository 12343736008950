export default defineNuxtRouteMiddleware(to => {
  if (/^\/?_nuxt/.test(to.path)) {
    return to;
  }

  if (!to.path.endsWith('/')) {
    const newTo = {
      path: to.path + '/',
      query: to.query,
      hash: to.hash,
    };

    return navigateTo(newTo, { redirectCode: 301 });
  }
});
